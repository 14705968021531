exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-category-page-category-page-js": () => import("./../../../src/templates/categoryPage/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-category-page-js" */),
  "component---src-templates-model-page-model-page-js": () => import("./../../../src/templates/modelPage/modelPage.js" /* webpackChunkName: "component---src-templates-model-page-model-page-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-user-page-user-page-js": () => import("./../../../src/templates/userPage/userPage.js" /* webpackChunkName: "component---src-templates-user-page-user-page-js" */),
  "component---src-templates-video-archive-video-archive-js": () => import("./../../../src/templates/videoArchive/videoArchive.js" /* webpackChunkName: "component---src-templates-video-archive-video-archive-js" */),
  "component---src-templates-video-post-video-post-js": () => import("./../../../src/templates/videoPost/videoPost.js" /* webpackChunkName: "component---src-templates-video-post-video-post-js" */)
}

